import React, { type ReactNode, useContext, useEffect, useMemo, useState } from 'react';

export type SwitcherTeam25ExperimentCohort = 'control' | 'test' | 'not-enrolled' | undefined;

// Temporary fix to enable the Growth team’s app recommendation section to access our experiment.
// TODO: https://hello.jira.atlassian.cloud/browse/NAVX-350
const storageKey = '@atlassian/switcher-team-25-experiment-cohort';

interface useSwitcherTeam25ExperimentResult {
	cohort: SwitcherTeam25ExperimentCohort;
	setCohort: (cohort: SwitcherTeam25ExperimentCohort) => void;
}

type SwitcherExperimentContextProvider = {
	switcherTeam25ExperimentCohort: SwitcherTeam25ExperimentCohort;
	setSwitcherTeam25ExperimentCohort: (cohort: SwitcherTeam25ExperimentCohort) => void;
};

export const ExperimentContext = React.createContext<SwitcherExperimentContextProvider | undefined>(
	undefined,
);

export const SwitcherExperimentProvider = ({ children }: { children: ReactNode }) => {
	const [switcherTeam25ExperimentCohort, setSwitcherTeam25ExperimentCohort] =
		useState<SwitcherTeam25ExperimentCohort>();

	const contextValue = useMemo<SwitcherExperimentContextProvider>(
		() => ({
			switcherTeam25ExperimentCohort,
			setSwitcherTeam25ExperimentCohort,
		}),
		[switcherTeam25ExperimentCohort, setSwitcherTeam25ExperimentCohort],
	);

	useEffect(() => {
		if (window?.sessionStorage && switcherTeam25ExperimentCohort) {
			window.sessionStorage.setItem(storageKey, switcherTeam25ExperimentCohort);
		}
	}, [switcherTeam25ExperimentCohort]);

	return <ExperimentContext.Provider value={contextValue}>{children}</ExperimentContext.Provider>;
};

export const useExperimentProvider = () => useContext(ExperimentContext);

export const useSwitcherTeam25Experiment = (): useSwitcherTeam25ExperimentResult => {
	const experimentContext = useExperimentProvider();

	return {
		cohort: experimentContext?.switcherTeam25ExperimentCohort,
		setCohort: experimentContext ? experimentContext.setSwitcherTeam25ExperimentCohort : () => {},
	};
};

export const useIsSwitcherTeam25TestCohort = () => {
	const { cohort } = useSwitcherTeam25Experiment();
	if (cohort) {
		return cohort === 'test';
	}

	// fallback to session storage
	return window?.sessionStorage?.getItem(storageKey) === 'test';
};
